import React from 'react';
import Feed from './Feed/Feed';
import Head from './Helper/Head';
import Categories from './Categories/Categories';
import Banners from './Banners/Banners';
import Contact from './Contact';
import ScrollToTop from "./Gotop";

const Inicial = () => {

  React.useEffect(() => {

  }, []);
  
  return (
    <div id="wrapper">

      <Categories/>

      <Banners/>

        <footer id="footer" className="panel">
          <Contact/>
        </footer>

        <ScrollToTop/>
    </div>
  );
};

export default Inicial;
