import React from 'react';
import styles from './FeedModal.module.css';
import useFetch from '../../Hooks/useFetch';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import { PHOTO_GET } from '../../Api';
import PhotoContent from '../Photo/PhotoContent';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../store/ui';
import { fetchPhoto } from '../../store/photo';

const FeedModal = ({ photo, setModalPhoto }) => {
  //const { data, error, loading, request } = useFetch();

  const { loading, error, data } = useSelector((state) => state.photo);
  const dispatch = useDispatch();

  //const { loading, error, data } = useSelector((state) => state.photo);
  //const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchPhoto(photo.id));
  }, [dispatch, photo]);

  /*
  React.useEffect(() => {
    //const { url, options } = PHOTO_GET(photo.id);
    //request(url, options);
    //console.log ('aqui aqui');

    async function verisso () {
      const { url, options } = PHOTO_GET(photo.id);
      //const json = request(url, options);
      const { response, json } = await request(url, options);
      //console.log ('no modal');
      //console.log (json);
    }
    verisso();


  }, [photo, request]);
  */

  function handleOutsideClick(event) {
    if (event.target === event.currentTarget) setModalPhoto(null);
  }

  return (
    <div className={styles.modal} onClick={handleOutsideClick}>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {data && <PhotoContent data={data} onClose={handleOutsideClick} />}
    </div>
  );
};

export default FeedModal;
