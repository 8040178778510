import React, { Component } from "react";

export default class ScrollToTop extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
          is_visible: true
        };
        this.navbar = null;
      }

  componentDidMount() {

    var self = this;
    this.navbar = document.getElementById('header');
    document.addEventListener("scroll", function(e) {
      self.toggleVisibility();
      self.navbarCollapse();
    });
  }


  navbarCollapse() {
      if (window.pageYOffset > 100) {
      this.navbar.classList.add("navbar-shrink");
    } else {
      this.navbar.classList.remove("navbar-shrink");
    }
  }


  toggleVisibility() {
    if (window.pageYOffset > 100) {
        this.setState({
          is_visible: true
        });
      } else {
        this.setState({
          is_visible: false
        });
      }
  }

  scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }

  render() {
    const { is_visible } = this.state;
    return (
        <div className="scroll-to-top-x">
        {is_visible && (
          <button className="btnscroll" onClick={() => this.scrollToTop()}>
          <i className="fa fa-chevron-up"></i>
          </button>
        )}
      </div>    
      );
  }
}