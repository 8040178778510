import React from 'react';
import { useParams } from 'react-router-dom';
import Error from '../Helper/Error';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../Helper/Loading';
import Head from '../Helper/Head';
import { adicionarDatas } from '../../store/date';
import { incrementar, reduzir } from '../../store/incrementar';
import { abrir, fechar } from '../../store/modal';
import { autoLogin, login } from '../../store/login';
import { UserContext } from '../../UserContext';

const Date = () => {
  const { id } = useParams();
  const [partida, setPartida] = React.useState('');
  const [retorno, setRetorno] = React.useState('');
  const [nome, setNome] = React.useState('');  
  
  const state = useSelector((state) => state.inc);
  const { inc2, modal } = useSelector((state) => state);

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { data } = useSelector((state) => state.login.user);

  const { userLogin, error, loading } = React.useContext(UserContext);

  const dispatch = useDispatch();

  React.useEffect(() => {
    
    async function verisso () {
    const payload = await dispatch(autoLogin());
    console.log (payload);
    }
    verisso ();

  }, [dispatch]);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(adicionarDatas({ partida, retorno, nome }));
    dispatch(incrementar(2));
  }

  function handleSubmit2() {
    dispatch(incrementar(2));
  }

  function handleSubmitLogin(event) {
    event.preventDefault();
    dispatch(login({ email, password }));
    //userLogin('', email, password);
  }

    return (
      <section className="container mainContainer">
        <Head title='Datas' />
        <form onSubmit={handleSubmit}>
        <input
          type="text"
          id="nome"
          value={nome}
          onChange={({ target }) => setNome(target.value)}
        />
        <p>
          <label htmlFor="partida">Partida</label>
          <input
            type="date"
            id="partida"
            value={partida}
            onChange={({ target }) => setPartida(target.value)}
          />
        </p>
        <p>
          <label htmlFor="retorno">Retorno</label>
          <input
            type="date"
            id="retorno"
            value={retorno}
            onChange={({ target }) => setRetorno(target.value)}
          />
        </p>
        <button>Buscar</button>
        </form>

        <div>
          <h1>Total: {state}</h1>
          <button onClick={() => dispatch({ type: 'INCREMENTAR' })}>
            Incrementar
          </button>
        </div>

        <div>
          <h1>Total: {inc2.inc} </h1>
          <button onClick={() => handleSubmit2()}>
            Incrementar 2
          </button>
        </div>


        <div>
          <h1>Total: {inc2.inc}</h1>
          <button onClick={() => dispatch({ type: 'incrementar/incrementar' })}>
            Incrementar
          </button>
        </div>


        {modal && <h1>Total: {inc2.inc}</h1>}

        <button onClick={() => dispatch(incrementar())}>Incrementar</button>
        <button onClick={() => dispatch(reduzir())}>Reduzir</button>
        <button onClick={() => dispatch(abrir())}>Abrir</button>
        <button onClick={() => dispatch(fechar())}>Fechar</button>


        <div>
      <form onSubmit={handleSubmitLogin}>
        <label style={{ display: 'block' }} htmlFor="email">
          Usuário
        </label>
        <input
          id="email"
          type="text"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
        <label style={{ display: 'block' }} htmlFor="password">
          Senha
        </label>
        <input
          id="password"
          type="text"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
        <button>Enviar</button>
      </form>
      <p>{data?.email}</p>
    </div>




      </section>
    );
};

export default Date;
