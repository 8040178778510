import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadBanners } from '../../store/banners';
import Loading from '../Helper/Loading';

const Banners = (props) => {
  const { list, loading } = useSelector((state) => state.banners);
  const dispatch = useDispatch();

    React.useEffect(() => {
      dispatch(loadBanners());
      ///dispatch(loadClima());
    }, []);
  
    return (
    <section className="banners">
      { loading  && <Loading />} 
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {list?.map((photo,index) => {
            return (
              <button key={index} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={`${index == 0 ? 'active' : ''}`} aria-current="true" aria-label={`Slide ${index+1}`}></button>)        
          })}
        </div>
        <div className="carousel-inner">
        {list?.map((photo,index) => {
          return (<div key={index} className={`carousel-item ${index == 0 ? 'active' : ''}`} >
          <img src={photo.imagem} className="d-block w-100" alt={photo.description}/>
        </div>)        
        })}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Anterior</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Próxima</span>
        </button>
        </div>

      </section>
    )
};

export default Banners;
