export const API_URL = 'https://comendus.com.br/api';


export function TOKEN_POST(body) {
  //console.log (body);
  return {
    url: API_URL + '/loginjwt',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function TOKEN_VALIDATE_POST(token) {
  return {
    url: API_URL + '/validatejwt',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',        
        Authorization: 'Bearer ' + token,
      },
    },
  };
}


  export function CLIMATEMPO_GET() {
    return {
      url: 'https://api.openweathermap.org/data/2.5/weather?lat=-30.0277&lon=-51.2287&appid=4e010b936c2cab40b3ea5e5c31855342',
      options: {
        method: 'GET',
        headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json'
        },
      },
    };
  }

export function USER_GET(token) {
  return {
    url: API_URL + '/club/user',
    options: {
      method: 'GET',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  };
}

export function USER_POST(body) {
  return {
    url: API_URL + '/club/newuser',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_POST(formData, token) {
  //console.log(formData);
  return {
    url: API_URL + '/club',
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: formData,
    },
  };
}

export function PHOTOS_GET({ page, total, user }) {
  return {
    url: `${API_URL}/club/getall?page=${page}&total=${total}&user=${user}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function PHOTO_SET(id, body) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

export function PAGE_GET(id) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function PHOTO_GET(id) {
  return {
    url: `${API_URL}/club/photo/${id}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  };
}

export function COMMENT_POST(id, body) {
  //console.log(id);
  //console.log(body);
  return {
    url: `${API_URL}/club/comments/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
      body: JSON.stringify(body),
    },
  };
}

export function PHOTO_DELETE(id) {
  return {
    url: `${API_URL}/club/${id}`,
    options: {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}

export function PASSWORD_LOST(body) {
  //console.log(body);
  return {
    url: API_URL + '/forgot-password',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function PASSWORD_RESET(body) {
  //console.log(body);
  return {
    url: API_URL + '/reset-password',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    },
  };
}

export function STATS_GET() {
  return {
    url: API_URL + '/club/stats',
    options: {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
      },
    },
  };
}
