import React from 'react';
import { CLIMATEMPO_GET } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { loadClima } from '../../store/climatempo';

const ClimaTempo = () => {

  const climatempox = useSelector((state) => state.climatempo);
  const dispatch = useDispatch();

  let imagem = '';
  const resultado = {
      "coord": {
        "lon": -51.2287,
        "lat": -30.0277
      },
      "weather": [
        {
          "id": 803,
          "main": "Clouds",
          "description": "broken clouds",
          "icon": "04d"
        }
      ],
      "base": "stations",
      "main": {
        "temp": 290.73,
        "feels_like": 291.05,
        "temp_min": 289.69,
        "temp_max": 291,
        "pressure": 1015,
        "humidity": 96
      },
      "visibility": 10000,
      "wind": {
        "speed": 1.54,
        "deg": 30
      },
      "clouds": {
        "all": 75
      },
      "dt": 1651173550,
      "sys": {
        "type": 2,
        "id": 2021802,
        "country": "BR",
        "sunrise": 1651139512,
        "sunset": 1651179161
      },
      "timezone": -10800,
      "id": 3452925,
      "name": "Porto Alegre",
      "cod": 200
    }


   // React.useEffect(() => {
   //   dispatch(loadClimatempo());
   //   setTimeout(setimage, 6000);
   // }, [dispatch]);

   React.useEffect(() => {
    dispatch(loadClima());
  }, [dispatch]);

  React.useEffect(() => {
    setimage();
  }, [climatempox]);

  function setimage() {
    let img = document.getElementById('climatempo'); 
    try{
      let imgt = `https://openweathermap.org/img/w/${climatempox.data.weather[0].icon}.png`;
      //let imgt = `icons/${climatempox.data.weather[0].icon}.png`;
      img.src = imgt;
      img.alt = climatempox.data.weather[0].description;
      img.title = climatempox.data.weather[0].description;
    } catch(e) {
      console.log(e);
    }
  }

  //this.horas = hor + ":" + min;
  //this.relogio.innerHTML = this.horas;
  function atualizarTempo()
  {
    fetch('https://api.openweathermap.org/data/2.5/weather?lat=-30.0277&lon=-51.2287&appid=4e010b936c2cab40b3ea5e5c31855342', {
      method: "GET",
      headers: {"Content-type": "application/json; charset=UTF-8"}
    })
    .then(response => response.json()) 
    .then(json => setimage(json))
    .catch(err => console.log(err));
  }

  return (
      <span><img id="climatempo" style={{ maxHeight: '2.1rem', backgroundColor: 'rgb(182 189 195 / 90%)', borderRadius: '20%'}} src=''/></span>
    );
};

export default ClimaTempo;
