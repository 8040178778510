import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'incrementar',
  initialState: {
    inc: 0,
  },
  reducers: {
    incrementar(state = 0, action) {
      state.inc = state.inc + 1;
    },
    reduzir(state = 0, action) {
      return {...state, inc: state.inc - 1 };
    },
    reduzir2: (state) => state.inc - 1,
  },
});

export const { incrementar, reduzir } = slice.actions;

export default slice.reducer;
