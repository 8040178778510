import React from 'react';
import axios from "axios";
import useForm from '../Hooks/useForm';
import Input from './Forms/Input';
import swal from 'sweetalert';
import Page from './Util/Page';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { loadPage } from '../store/pages';
import { loadPage as loadPageTal } from '../store/page';


const types = {
    email: {
      regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Preencha um email válido',
    },
    password: {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
      message:
        'A senha precisa ter 1 caracter maíusculo, 1 minúsculo e 1 digito. Com no mínimo 8 caracteres.',
    },
    number: {
      regex: /^\d+$/,
      message: 'Utilize números apenas.',
    },
  };

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '90%',
        minHeight: '75vh',
        bottom: 'auto',
        marginTop: '30px',
        marginRight: '-50%',
        border: '4px solid #212529',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgb(65 71 74)',
        position: 'relative',
        zIndex: '2000',
    },
    }; 


const Contact = (props) => {

  const dispatch = useDispatch();
  const paginas = useSelector((state) => state.pages.list);

   React.useEffect(() => {
    dispatch(loadPage(0));
  }, []);
  
  const [name, setName] = React.useState('');
  const [contato, setContato] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [modalinfo, setModalInfo] = React.useState(false);
  const [paginaAtual, setPaginaAtual] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [pageName, setPageName] = React.useState('');
  const [lastPage, setLastPage] = React.useState('');
  const [error, setError] = React.useState(true);
  const [mensagem, setMensagem] = React.useState('Entre em contato');
  
  function handleSubmit (event) {

      event.preventDefault()

      const mensagem = {
          name: name,
          email: email,
          message: msg
        };
    
      var self = this;

      if (!error && name && email && msg) {
          var qs = require('qs');
          axios ({
              method: 'POST',
              url: process.env.PUBLIC_URL + 'controllers/contact_me.php',
              data: qs.stringify(mensagem),
              headers: {}, 
              transformRequest: [(data, headers) => {
                  return data;
              }]
          }).then(function (response) {

                  setName('');
                  setEmail('');
                  setMsg('');
                  setError(true);
                  setMensagem('Mensagem enviada com sucesso!, entraremos em contato em breve.');

          }).catch(function (error) {

                  setName('');
                  setEmail('');
                  setMsg('');
                  setError(true);
                  setMensagem('Ocorreu um erro no envio!, tente chamar pelo whatsapp no final da página.');

              console.log('Error: ', error);
          });
      } else {
          swal("Campos Obrigatorios!", {
              icon: "error",
              timer: 2000
              });              
      }

  }

  function validate (event) {
      if (event.target.value.length === 0) {
          
          swal("Preencha um valor!", {
              icon: "error",
              timer: 2000
              });  

        this.setState({
          error : true,
          })
        return false;
      } else {
          console.log('sem erro');
          setError(false);
        return true;
      }
    }

  function setNameField (event) {
//      if (error) validate(event.target.value);
      setName(event.target.value);
  }

  function setEmailField (event) {
//      if (error) validate(event.target.value);
      setEmail(event.target.value);
  }

  function setMsgField (event) {
//      if (error) validate(event.target.value);
      setMsg(event.target.value);
  }

  function handleOpenModal (event,page,name) {
    event.preventDefault();
    setModalInfo(true);
    setPage(page);
    setPageName(name);
    //showPage(page);
  }

  function handleOpenSpace (event,page,name) {
    event.preventDefault();
    if (page == 0) {
      if (!contato) { 
      setContato(true);
      setPaginaAtual(false);
      setLastPage('');
    } else {
        setContato(false);
        setPaginaAtual(false);
        setLastPage('');
      }
    } else {
      if (lastPage != name) {
      setContato(false);
      setPaginaAtual(true);
      dispatch(loadPageTal(page));
      setPage(page);
      setPageName(name);
      setLastPage(name);
      } else {
        setContato(false);
        setPaginaAtual(false);
        setLastPage('');
      }
    }
  }

  function handleCloseModalInfo (event) {
    var aqui = this;
    var modalinfo = document.getElementById("Modalinfo");
    //var modal = document.querySelectorAll('[overlayClassName]');
    event.preventDefault()
    modalinfo.parentNode.classList.add("fade-out-bottom");
    var self = this;
    setTimeout(function(){ 
      setModalInfo(false);  
      }, 1000);
    }

    return(
    <div className="inner split" style={{ flexDirection: 'column'}}>
            <div style={{width: '100%'}}>
              <section style={{display : 'none'}}>
                <h2>Seja bem vindo(a) ao nosso portal</h2>
                <p>Aqui você vai encontrar uma variedade de eventos, shows, musicais, festas, entretenimento em geral. Faça sua pesquisa e aproveite melhor o seu tempo.</p>
              </section>
              <section>
                <ul className="icons">
                {paginas?.map((item) => (
                  <li key={item.id}><a className="itema" onClick={(e) => handleOpenSpace(e,item.id,item.title)}  style={{ cursor: 'pointer', color: '#ffeb98'}}>{item.title}</a></li>
                ))}
                  <li><a onClick={(e) => handleOpenSpace(e,0,'Contato')}  style={{ cursor: 'pointer', color: '#ffeb98'}}>Contato</a></li>
                </ul>
                <h3 style={{display : 'none'}}>Se você quiser anunciar em nosso portal: <br/><a class="btn btn-success btn-sm mh-100 mx-2" href="https://web.whatsapp.com/send?phone=555181383021&amp;text=Quero anunciar com vocês" title="Quero Anunciar" target="_blank" className="btn btn-success" style={{ margin : '10px 0' }}>
                  <i class="fa fa-whatsapp" aria-hidden="true"></i> Clique aqui !!!</a></h3> 
              </section>
            </div>
            <div style={{ width: '100%'}}>
              <section>

               { contato &&
               <>
                <div className="row">
                <div className="col-md-6">
                <h2><strong>{mensagem}</strong></h2>
                <form onSubmit={handleSubmit} name="sentMessage" id="contactFormulario" noValidate="noValidate">
                  <div className="fields">
                    <div className="field half">
                      <input type="text" name="name" id="name" required value={name} onChange={setNameField} onBlur={validate} placeholder="Nome" />
                    </div>
                    <div className="field half">
                      <input type="text" id="email" required name="email" value={email} onChange={setEmailField}  placeholder="Email" />
                    </div>
                    <div className="field">
                      <textarea name="message" id="message" required value={msg} onChange={setMsgField} rows="4" placeholder="Mensagem"></textarea>
                    </div>
                  </div>
                  <ul className="actions">
                    <li><input type="submit" value="Enviar" className="primary special" /></li>
                  </ul>
                </form>
                </div>
                <div className="col-md-6">
                <h3>Nos envie uma mensagem de whatsapp: <a class="btn btn-success btn-sm mh-100 mx-2" href="https://web.whatsapp.com/send?phone=555181383021&amp;text=Quero conversar com vocês" title="Quero Conversar" target="_blank" className="btn btn-success" style={{ margin : '10px 0' }}>
                  <i class="fa fa-whatsapp" aria-hidden="true"></i></a></h3> 
                </div>
                </div>
                </>
                }

                { paginaAtual &&
                <div>
                <h2><strong>{pageName}</strong></h2>
                <Page pagina={page}/>
                </div>
                }


              </section>
            </div>


            <ReactModal id="Modalinfo" className="Modal" style={customStyles} ariaHideApp={false} overlayClassName="OverlayPage bounce-in-top"
          isOpen={modalinfo}
          contentLabel="Minimal Modal Example"
          >
          <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
          <div className="container-fluid">
              <div className="navbar-header">
              <div className="navbar-brand"><i className="fa fa-fw fa-image"></i>
              {pageName}</div>
              </div>

                  <button className="btn btn-danger btn-sm m-3 float-right" onClick={handleCloseModalInfo}>X</button>


          </div>
          </nav>
          
          <div className="area ck-content" style={{ maxHeight: 'calc(100vh - 15em)', overflowY : 'auto'}}> 
          <div className="container-fluid">
              <div className="row">
                <Page pagina={page}/>
              </div>
          </div>

          </div>

      </ReactModal>

          </div>
        );
    }

export default Contact;