import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Dogs } from '../Assets/comendus.svg';
import { UserContext } from '../UserContext';
import Relogio from './Util/Relogio';
import ClimaTempo from './Util/Climatempo';
import Sobre from './Util/Sobre';
import styles from './Header.module.css';
import moment from 'moment';
import localization from 'moment/locale/pt';

const Header = () => {
  let dataAtual = 'Porto Alegre - ' + moment().locale("pt", localization).format('LL') + '.';

  React.useEffect(() => {

    //dataAtual = moment().format('HH:mm:ss');

  }, []);


  return (
    <header id="header">
      <div className={styles.divx}>
      <h1><a href="/" className={styles.logo}>entradafranca.com</a></h1>
      <p className="tituloaproveite">aproveite&nbsp;&nbsp;<span>&nbsp;melhor&nbsp;</span>&nbsp;&nbsp;o&nbsp;&nbsp;tempo&nbsp;&nbsp;livre</p>
      </div>
      <nav style={{ height : '100%' }}>
        <ul style={{ height : '100%', marginTop : '-10px', zIndex : '10'}}>
          <li style={{ height : '100%'}}><a style={{ height : '100%'}} className={styles.menu}><Relogio/><ClimaTempo/><Sobre/></a></li>
        </ul>
        <p className={styles.data} style={{ zIndex : '0'}}>{ dataAtual }</p>
      </nav>
    </header>
  );
};

export default Header;
