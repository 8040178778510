import React from 'react';
import { useSelector } from 'react-redux';
import styles from './PhotosContent.module.css';
import PhotosContentItem from './PhotosContentItem';
import { getOverFiveKg, getbyTitle } from '../store/photos';

const PhotosContent = () => {
  const { list } = useSelector((state) => state.photos);
  let lista = getOverFiveKg(list);

  React.useEffect(() => {
    //setListax(lista);
    //lista = getOverFiveKg(list);
    //setListax(getOverFiveKg(list));
    //console.log('lista');
    //console.log(listax);
    
  }, []);


  return (
    <div>
    <ul className={styles.list}>
      {list?.map((photo,index) => (
        <li key={index} className={`${styles.item} anime`}>
          <img src={photo.src} alt={photo.title} className={styles.img} />
          <PhotosContentItem id={photo.id} src={photo.src} title={photo.title}/>
          <span className={styles.acessos}>{photo.acessos}</span>
        </li>
      ))}
    </ul>
    </div>
  );
};

export default PhotosContent;
