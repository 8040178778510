import React from 'react';

const Sobre = () => {

const [active, setActive] = React.useState(false);

function ativar (e) {
  setActive(!active);
  let Footer = document.querySelector('#footer');
  active ? Footer.classList.remove('active') : Footer.classList.add('active');
  }

  return (
      <span id="btnSobre" onClick={() => ativar()} style={{ marginLeft : '10px', cursor : 'pointer'}} className="icon solid fa fa-bars"></span>
    );
};

export default Sobre;
