import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCategories } from '../../store/categories';
import { loadEvents } from '../../store/events';
import moment from 'moment';
import ReactModal from 'react-modal';
import Loading from '../Helper/Loading';
import Mymodal from '../Mymodal';
import ContactInfo from '../ContactInfo';
import styles from './Categories.module.css';
import IF from './if';

const Categories = (props) => {
  const { list, loading } = useSelector((state) => state.categories);
  const climatempox = useSelector((state) => state.climatempo);
  const eventosearch = useSelector((state) => state.events);
  const listorder = [];
  const dispatch = useDispatch();
  const [listax, setListax] = React.useState('');
  const [error, setError] = React.useState('');
  const [category, showCategory] = React.useState('');
  const [categoryid, showCategoryId] = React.useState('');
  const [events, showEvents] = React.useState([]);
  const [evento, showEvento] = React.useState([]);
  const [modal, showModal] = React.useState(false);
  const [agenda, showAgenda] = React.useState('');
  const [modalinfo, showModalInfo] = React.useState(false);
  const [modaldet, showModalDet] = React.useState(false);
  //const [loading, setLoading] = React.useState(true);
  const d = new Date();
  let ref = null;
  let diferences = [];

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        width: '100%',
        minHeight: 'calc(100vh - 5em)',
        bottom: 'auto',
        marginTop: '30px',
        marginRight: '-50%',
        border: '4px solid #212529',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgb(65 71 74)',
        position: 'relative'
    },
    }; 

  React.useEffect(() => {
    dispatch(loadCategories());
    ///dispatch(loadClima());
  }, [dispatch]);
  
  React.useEffect(() => {
    console.log(eventosearch.list);
    showEvents([]);
    showEvents(eventosearch.list);
  }, [eventosearch]);



  React.useEffect(() => {

		setTimeout (ligatooltip,1000);

		function ligatooltip() {
		var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    console.log (tooltipTriggerList);
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })
		}    

  }, [events]);

  //React.useEffect(() => {
    //setTimeout(setLoading(false), 5000);
  //}, [setLoading]);

  function setclassname() {
  }
 
  function minMax(items) {
    return items.reduce((acc, val) => {
        acc[0] = ( acc[0] === undefined || val < acc[0] ) ? val : acc[0]
        acc[1] = ( acc[1] === undefined || val > acc[1] ) ? val : acc[1]
        return acc;
    }, []);
  }

  function handleOpenModal (event,photo) {
    event.preventDefault();
    showModal(true);
    showCategory(photo.name);
    showCategoryId(photo.id);
    showEvents(photo.events);
    //dispatch(loadClima());
    //this.setState({ showModal: true, loading: true, type: 1 });
    //this.setAtualizarParam(1);
}

function handleOpenModalInfo (event,photo) {
  event.preventDefault();
  showModalInfo(true);
  showCategory(photo.name);
}

  function handleCloseModal (event) {
    var aqui = this;
    var modal = document.getElementById("Modal");
    //var modal = document.querySelectorAll('[overlayClassName]');
    event.preventDefault()
    modal.parentNode.classList.add("fade-out-bottom");
      setTimeout(function(){ showModal(false)}, 500);
    }

    function handleCloseModalInfo (event) {
      var aqui = this;
      var modalinfo = document.getElementById("Modalinfo");
      //var modal = document.querySelectorAll('[overlayClassName]');
      event.preventDefault()
      modalinfo.parentNode.classList.add("fade-out-bottom");
        setTimeout(function(){ showModalInfo(false)}, 500);
      }

  function listareventos(event) {

    //return (events?.map((event,index) => {
    //    <h1>oi</h1>
    //}))

  }

  function abrirjanela(event) {
    ref = React.createRef();
    showEvento(event);
    showModalDet(true);
  }

  function setAgenda(event) {
    let hoje = document.querySelector('#hoje');
    let mes = document.querySelector('#mes');
    let ano = document.querySelector('#ano');
    hoje.classList.remove('active');
    mes.classList.remove('active');
    ano.classList.remove('active');
    event.target.classList.add('active');
    showAgenda(event.target.id);
    if (event.target.id == 'hoje') {
      dispatch(loadEvents(`${categoryid}?today=ok`));
    } else if (event.target.id == 'mes') {
      dispatch(loadEvents(`${categoryid}?month=ok&year=ok`));
    } else {
      dispatch(loadEvents(`${categoryid}?year=ok`));
    }
  }

  return (
    <section className="cards">
      { loading  && <Loading />} 
      {list?.map((photo,index) => {
        var className = '';
        let startTime = moment().format('HH:mm:ss');
        let startTimeH = 0;
        let falta = '';
        let endTime = '';
        let endTimeH = 0;
        let eventos = [];
        let difm = 0;
        let difh = 0;
        let diftotal = 0;
        let duracao = 0;
        let id = 0;


        if (photo.events.length) {

          diferences = [];
          eventos = photo.events;
          eventos.forEach(function (value, i) {

            id = photo.events[i].id;
            //startTimeH = moment().format('HH');
            //endTimeH = moment(`${photo.events[i].date} ${photo.events[i].time}`).format('HH');
            endTime = moment(`${photo.events[i].date} ${photo.events[i].time}`).format('HH:mm:ss');
 
            if (endTime >= startTime) {

              difm = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format("mm")
              difh = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format("HH")
              diftotal = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format("HH:mm")
    
              duracao = moment.duration(diftotal).asMinutes();
              diferences.push({id: id, amount: duracao, horario: endTime});
            }

            difm = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format("mm")
            difh = moment.utc(moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))).format("HH")
  
            //if (difh < 1 && difm <= 30) {className = photo.size || 'item2x2'}
            falta = difh + ':' + difm;

            diferences.sort(function (a, b) {return a.amount - b.amount })  
            if (diferences.length) {
                  endTime = `${diferences[0]['horario']}`;
            } else {
              endTime = 'Finalizado';
              if (difh > 1) {
                  //endTime = 'Finalizados'
                  //console.log ('1',endTime, difh, difm);
              } else {
                  //console.log ('outro',endTime, difh, difm);
              }
            }

          });
      

        } 

        return (<article className={`${className} scale-up-center`} key={index}>
          {photo.events.length ? <img className="article-img" src={photo.imagem} alt={photo.name} />  : <img className="article-img" src={photo.imagem} alt={photo.name} />}    
          <a onClick={(e) => handleOpenModal(e,photo)} style={{ cursor: 'pointer'}}>
            <h1 className="article-title">
            {photo.name}</h1>
          </a>
        </article>
        )        

      })}


      <ReactModal id="Modal" className="Modal ModalCat" ariaHideApp={false} overlayClassName="Overlay bounce-in-top"
          isOpen={modal}
          contentLabel="Minimal Modal Example"
          >
          <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
          <div className="container-fluid">
              <div className="navbar-header">
              <div className="navbar-brand"><i className="fa fa-fw fa-image"></i> {category}</div>
              </div>

              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Agenda
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a id="hoje" onClick={(e) => setAgenda(e)} className="dropdown-item active" href="#">Hoje</a></li>
                  <li><a id="mes" onClick={(e) => setAgenda(e)} className="dropdown-item" href="#">Esse Mês</a></li>
                  <li><a id="ano" onClick={(e) => setAgenda(e)} className="dropdown-item" href="#">Esse Ano</a></li>
                </ul>
              </div>

              <button className="btn btn-danger btn-sm m-3 float-right" onClick={handleCloseModal}>X</button>
              </div>

          </div>
          </nav>
          
          <div className="area" style={{ maxHeight: 'calc(100vh - 10em)', overflowY : 'auto'}}> 
          <div className="container-fluid">
              <div className="row justify-content-center">

              {!events.length  && <ContactInfo/>}
              {eventosearch.loading  && <Loading />} 
              {events.map((event,i) => (
                  <div key={event.id} className="col-11 col-sm-11 col-md-11">
                  <div className="card p-1 m-3">
                  <div className={`card-body ${styles.body}`}>
                      <div className="row">
                        <IF test={ parseInt(event.visible_time) != 2 }>
                        <div className="col-12 col-sm-6 col-md-2">
                          
                          <IF test={ parseInt(moment(`${event.date} ${event.time}`).format('mm')) }>
                            <div style={{ width : '100%'}} className={styles.hora} aria-current="page">{moment(`${event.date} ${event.time}`).format('HH:mm')}</div>
                          </IF>

                          <IF test={ !parseInt(moment(`${event.date} ${event.time}`).format('mm')) }>
                            <div style={{ width : '100%'}} className={styles.hora} aria-current="page">{moment(`${event.date} ${event.time}`).format('HH')}</div>
                          </IF>
                          
                        </div>
                        </IF>
                        <div className={`ck-content col-12 col-sm-6 ${event.visible_time == 1 ? 'col-md-5' : 'col-md-6'}`}>
                          <div dangerouslySetInnerHTML={{ __html: event.body }}></div>
                        </div>
                        <div className={`ck-content col-12 col-sm-6 ${event.visible_time == 1 ? 'col-md-5' : 'col-md-6'}`}>
                         <p className={styles.texto}>{event.place}</p>
                         <p className={styles.texto}>{event.address}</p>
                         <p className={styles.obs}>{event.obs}</p>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
                ))}

                </div>
          </div>

          </div>

      </ReactModal>


      <ReactModal id="Modalinfo" className="Modal" style={customStyles} ariaHideApp={false} overlayClassName="Overlay bounce-in-top"
          isOpen={modalinfo}
          contentLabel="Minimal Modal Example"
          >
          <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
          <div className="container-fluid">
              <div className="navbar-header">
              <div className="navbar-brand"><i className="fa fa-fw fa-image"></i> {category}</div>
              </div>

                  <button className="btn btn-danger btn-sm m-3 float-right" onClick={handleCloseModalInfo}>X</button>


          </div>
          </nav>
          
          <div className="area" style={{ maxHeight: 'calc(100vh - 15em)', overflowY : 'auto'}}> 
          <div className="container-fluid">
              <div className="row">
                <ContactInfo/>
                </div>
          </div>

          </div>

      </ReactModal>


      <Mymodal ref={ref} show={modaldet} evento={evento} link = 'link' text = 'Arquivo' onHide = {showModalDet}/> 

      </section>
    )
};

export default Categories;
