import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../../Hooks/useFetch';
import { PHOTO_GET } from '../../Api';
import Error from '../Helper/Error';
import Loading from '../Helper/Loading';
import PhotoContent from './PhotoContent';
import { useDispatch, useSelector } from 'react-redux';
import Head from '../Helper/Head';
import { fetchPhoto } from '../../store/photo';

const Photo = () => {
  const { id } = useParams();
  const { loading, error, data } = useSelector((state) => state.photo);
  //const { data, loading, error, request } = useFetch();
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  
  React.useEffect(() => {
    dispatch(fetchPhoto(id));
  }, [dispatch, id]);

  /*
  React.useEffect(() => {
    async function verisso () {
      const { url, options } = PHOTO_GET(id);
      //const json = request(url, options);
      const { response, json } = await request(url, options);
      //console.log ('dados do photo');
      //console.log (json);
    }
    verisso();
  }, [request, id]);
  */

  if (error) return <Error error={error} />;
  if (loading) return <Loading />;
  if (data)
    return (
      <section className="container mainContainer">
        <Head title={data.photo.title} />
        <button onClick={() => dispatch({ type: 'INCREMENTAR' })}>Aqui2: {state.inc}</button>
        <button onClick={() => navigate('/datas')}>Aqui2: {state.inc2.inc}</button>
        <PhotoContent single={true} data={data} />
      </section>
    );
  else return null;
};

export default Photo;
