import React from 'react';
import styles from './UserPhotoPost.module.css';
import useForm from '../../Hooks/useForm';
import useFetch from '../../Hooks/useFetch';
import Input from '../Forms/Input';
import Button from '../Forms/Button';
import Erro from '../Helper/Error';
import Erros from '../Helper/Erros';
import { PHOTO_POST } from '../../Api';
import { useNavigate } from 'react-router-dom';
import Head from '../Helper/Head';
import { UserContext } from '../../UserContext';

const UserPhotoPost = () => {
  const nome = useForm();
  const peso = useForm('number');
  const idade = useForm('number');
  const [img, setImg] = React.useState({});
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const { data, loading, request, response } = useFetch();
  const navigate = useNavigate();

  const { userLogout } = React.useContext(UserContext);

  React.useEffect(() => {

  }, [data, navigate]);

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setError(null);
      const formData = new FormData();
      formData.append('img', img.raw);
      formData.append('nome', nome.value);
      formData.append('peso', peso.value);
      formData.append('idade', idade.value);
  
      const token = window.localStorage.getItem('token');
      const { url, options } = PHOTO_POST(formData, token);
      
      //const tokenRes = await request(url, options);
      const { response, json } = await request(url, options);

      if (response.ok) {
        navigate('/conta');
        //if (data) navigate('/conta');
      } else {
        setErrors(json.errors);
        throw new Error(json.message);  
      }
  
    } catch (erro) {
      if (erro.message === 'The given data was invalid.') {
        setError('Dados inválidos.');
      } else if (erro.message === 'Token has expired') {
        setError('Você precisa se logar novamente.');
        setTimeout(userLogout(), 5000);
      } else {
        setError(erro.message);
        //setTimeout(userLogout(), 5000);
      }
    } finally {
      //setLoading(false);
    }

  }

  function handleImgChange({ target }) {
    setImg({
      preview: URL.createObjectURL(target.files[0]),
      raw: target.files[0],
    });
  }

  return (
    <section className={`${styles.photoPost} animeLeft`}>
      <Head title="Poste sua foto" />
      <form onSubmit={handleSubmit}>
        <Input label="Descrição" type="text" name="nome" {...nome} />
        <input
          className={styles.file}
          type="file"
          name="img"
          id="img"
          onChange={handleImgChange}
        />
        {loading ? (
          <Button disabled>Enviando...</Button>
        ) : (
          <Button>Enviar</Button>
        )}
        <Erro error={error} />
        <Erros errors={errors} />
      </form>
      <div>
        {img.preview && (
          <div
            className={styles.preview}
            style={{ backgroundImage: `url('${img.preview}')` }}
          ></div>
        )}
      </div>
    </section>
  );
};

export default UserPhotoPost;
