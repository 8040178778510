import React from 'react';
import styles from './Footer.module.css';
import { ReactComponent as Dogs } from '../Assets/comendus.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
     <h2><a className={styles.logo} href="/">entradafranca.com</a></h2>
      <p>Lazer, Cultura & Bem Estar Social</p>
    </footer>
  );
};

export default Footer;
