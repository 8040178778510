import React from 'react';
import styles from './PhotoContent.module.css';
import { Link } from 'react-router-dom';
import PhotoComments from './PhotoComments';
import { UserContext } from '../../UserContext';
import PhotoDelete from './PhotoDelete';
import Image from '../Helper/Image';
import { useSelector } from 'react-redux';

const PhotoContent = ({ onClose, data, single }) => {
  const user = React.useContext(UserContext);
  const { photo, comments } = data;

  //const { user } = useSelector((state) => state);
  //const { photo, comments } = useSelector((state) => state.photo.data);

  console.log('photo');
  console.log(photo);

  //const { user2 } = useSelector((state) => state);
  const ver = useSelector((state) => state.photo);

  console.log('photo');
  console.log(ver);

  //console.log('user2');
  //console.log(user2);


  //console.log ('cometarios:');
  //console.log (data);
  return (
    <div className={`${styles.photo} ${single ? styles.single : ''}`}>
      <div className={styles.img}>
        <Image src={photo.src} alt={photo.title} />
      </div>
      <div className={styles.details}>
        <div>
          <p className={styles.author}>
            {user.data && user.data.username == photo.author ? (
              <PhotoDelete id={photo.id} />
            ) : (
              <Link to={`/perfil/${photo.author}`}>@{photo.author}</Link>
            )}
            <span className={styles.visualizacoes}>{photo.acessos}</span>
            {onClose && 
              <span id="close" style={{ cursor : 'pointer' }} onClick={(e) => onClose(e)} className={styles.fechar}>X</span>
            }
          </p>
          {photo.title && (
            <h1 className="title">
              <Link to={`/foto/${photo.id}`}>{photo.title}</Link>
            </h1>
          )}
        </div>
      </div>
      <PhotoComments single={single} id={photo.id} comments={comments} />
    </div>
  );
};

export default PhotoContent;
