import createAsyncSlice from './helper/createAsyncSlice';

export const API_URL = 'https://entradafranca.com/api';

let events = createAsyncSlice({
  name: 'events',
  initialState: {
    list: [],
    user: 0,
    pages: 0,
    infinite: true,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    clearPhotos(state, action) {
      state.list = [];
    },
    addPhotos(state, action) {
      state.list.push(...action.payload);
      state.list.sort(function (a, b) {return b.total - a.total })
      //state.pages++;
      //if (action.payload.meta.current_page === action.payload.meta.last_page) state.infinite = false;
    },
    removePhotos(state) {
      state.pages = 0;
      state.infinite = true;
      state.list = [];
      state.data = null;
    },
    searchbyTitle(state, action) {

      const data = action.payload.payload.data;
      const overFiveKg = data?.filter(({ title }) => title.toUpperCase().indexOf(action.payload.chave.toUpperCase()) > -1);
      const transformPound = overFiveKg?.map((photo) => ({
        ...photo,
        peso: Math.floor(photo.peso * 5.2),
      }));

      state.list.push(...transformPound);
      state.infinite = false;
    },
  },
  fetchConfig: (data) => ({
    url: `${API_URL}/categories/${data}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});

const slice = createAsyncSlice({
  name: 'photos',
  initialState: {
    cache: 5000,
    list: [],
    user: 0,
    pages: 0,
    infinite: true,
  },
  fetchConfig: () => ({
    url:
    `${API_URL}/club/getall?page=1&total=6&user=0`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});


export const {  addPhotos, addPhotos2, searchbyTitle, setUser, clearPhotos, removePhotos } = events.actions;
export const fetchPhotos = events.asyncAction;
export const fetchPhotos2 = slice.asyncAction;

export const getOverFiveKg = (state) => {
  const data = state;
  const overFiveKg = data?.filter(({ peso }) => peso >= 0);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 2.2),
  }));
  return transformPound;
};

export const getbyTitle = (state, chave) => {
  console.log(chave);
  const data = state;

  const overFiveKg = data?.filter(({ title }) => title.indexOf(chave) > -1);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 5.2),
  }));
  return transformPound;
};

export const loadEvents = (data) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos(data));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export const searchTitle = (chave = '',user = 0) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({user:user}));
  dispatch(removePhotos());
  dispatch(searchbyTitle({payload, chave}));
};

export const loadNewPhotosInicial = (page = 1, user = 0) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({page, user}));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export default events.reducer;
