import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadNewPhotosInicial, searchTitle, fetchPhotos2 } from '../store/photos';
import PhotosContent from './PhotosContent';
import PhotosContent2 from './PhotosContent2';
import PhotosLoadMore from './PhotosLoadMore';
import Erro from '../Components/Helper/Error';
import useForm from '../Hooks/useForm';
import Input from '../Components/Forms/Input';
import Button from '../Components/Forms/Button';
import styles from './Photos.module.css';
import { getOverFiveKg, getbyTitle } from '../store/photos';

const Photos = (props) => {
  //const data =  useSelector((state) => state.photos);
  const dispatch = useDispatch();
  const title = useForm();
  let lista = [];
  const [listax, setListax] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    dispatch(loadNewPhotosInicial(1,props.user));
    //const data = state.photos;
    //dispatch(fetchPhotos2());
    //console.log('data');
    //console.log(data);
  }, [dispatch]);

  function handleSubmit(event) {
    event.preventDefault();
    try {
      dispatch(searchTitle(title.value,props.user));
      //console.log()
      //setListax(title.value);
      //console.log('listax');
      //console.log(listax);
      //setTimeout(alert(listax), 5000);
    } 
    catch(err) {

    }
    }

  return (
    <section>
      <form className={`${styles.formulariosearch} anime`} onSubmit={handleSubmit}>
        <Input label="Pesquisar" type="text" name="title" {...title} />
        {loading ? (
          <Button disabled>Pesquisando...</Button>
        ) : (
          <Button>Pesquisar</Button>
        )}
        <Erro error={error} />
      </form>
      <PhotosContent />
      <PhotosLoadMore user={props.user} />
    </section>
  );
};

export default Photos;
