import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';

export default class Mymodal extends Component {
 
  constructor(props) {
    super(props);
    //this.wrapper = React.createRef();    
  }
   
  componentWillMount() {
    //this.props.getLogin(this.props.route.login)
    //const { id } = this.props.summary
  console.log('mount');  
  }

  render () {

    const checaextensao = () => {

      this.arq = 'https://entradafranca.com/storage/' + this.props.evento.image;
      let ext = this.arq.split('.').pop();

      if (ext === 'gif' || ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
        return (
          <img style={{margin : '0 auto'}} className="img-fluid rounded text-center" src={process.env.PUBLIC_URL + this.arq} alt=""/>
        )} else if (ext === 'pdf') {
          return (
            <iframe src={process.env.PUBLIC_URL + this.arq} style={{ width: '100%', height: '50vh', border : 'none'}} title={ext}></iframe>
          )} else if (ext === 'mp3' || ext === 'wav' || ext === 'm4a') {
            return (
              <audio controls>
              <source src={process.env.PUBLIC_URL + this.arq} type="audio/mpeg"/>
              </audio>
          )} else {
            return (
              <a href={process.env.PUBLIC_URL + this.arq} target="_blank" rel="noreferrer">Clique para abrir</a>  
            )
          }

    }


    return (
 
      <Modal 
      {...this.props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      ref={this.props.ref}
    >
      <Modal.Header closeButton >
        <Modal.Title id="contained-modal-title-vcenter">
        <h2 className="portfolio-modal-title text-secondary mb-0">{this.props.evento.title}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body  className="modal-body2">
      <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                {this.props.evento.image && checaextensao()}
                <div dangerouslySetInnerHTML={{ __html: this.props.evento.body }}></div>
              </div>
            </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer2">
      </Modal.Footer>
    </Modal>
 
      );
  }
}
