import React from 'react';
import axios from "axios";
import useForm from '../Hooks/useForm';
import Input from './Forms/Input';
import swal from 'sweetalert';

const types = {
    email: {
      regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Preencha um email válido',
    },
    password: {
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/,
      message:
        'A senha precisa ter 1 caracter maíusculo, 1 minúsculo e 1 digito. Com no mínimo 8 caracteres.',
    },
    number: {
      regex: /^\d+$/,
      message: 'Utilize números apenas.',
    },
  };

export default class ContactInfo extends React.Component {

    constructor (props) {
        super (props)

        this.state = {
            name: '',
            email: '',
            msg: '',
            error: true,
            mensagem: 'Envie o evento da sua cidade'
        }

    }

    
    handleSubmit= (event) => {

        event.preventDefault()

        const mensagem = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.msg
          };
      
        var self = this;

        if (!this.state.error && this.state.name && this.state.email && this.state.msg) {
            var qs = require('qs');
            axios ({
                method: 'POST',
                url: process.env.PUBLIC_URL + 'controllers/contact_me.php',
                data: qs.stringify(mensagem),
                headers: {}, 
                transformRequest: [(data, headers) => {
                    return data;
                }]
            }).then(function (response) {

                    self.setState({
                        name : '',
                        email : '',
                        msg : '',
                        error: true,
                        mensagem: 'Mensagem enviada com sucesso!, entraremos em contato em breve.'
                    })

            }).catch(function (error) {

                self.setState({
                    name : '',
                    email : '',
                    msg : '',
                    error: true,
                    mensagem: 'Ocorreu um erro no envio!, tente chamar pelo whatsapp no final da página.'
                })

                console.log('Error: ', error);
            });
        } else {
            swal("Campos Obrigatorios!", {
                icon: "error",
                timer: 2000
                });              
        }

    }

    validate = (event) => {
        if (event.target.value.length === 0) {
            
            swal("Preencha um valor!", {
                icon: "error",
                timer: 2000
                });  

          this.setState({
            error : true,
            })
          return false;
        } else {
            console.log('sem erro');
            this.setState({
                error : false,
                })
           return true;
        }
      }

    setName = (event) => {
        if (this.error) this.validate(event.target.value);
        this.setState({
            name: event.target.value
        })
    }

    setEmail = (event) => {
        if (this.error) this.validate(event.target.value);
        this.setState({
            email: event.target.value
        })
    }

    setPhone = (event) => {
        this.setState({
            phone: event.target.value
        })
    }

    setMsg = (event) => {
        if (this.error) this.validate(event.target.value);
        this.setState({
            msg: event.target.value
        })
    }

    render(){

        return(
    <div id="lessons" className="inner split" >
            <div>
              <section>
                <h2>Você pode colaborar conosco!</h2>
                <p>Envie eventos que vão ocorrer em sua cidade, basta preencher o formulário abaixo e divulgaremos aqui.</p>
              </section>
              <section>
                <h2><strong>{this.state.mensagem}</strong></h2>
                <form onSubmit={this.handleSubmit} name="sentMessage" id="contactFormulario" noValidate="noValidate">
                  <div className="fields">
                    <div className="field half">
                      <input type="text" name="name" id="name" required value={this.state.name} onChange={this.setName} onBlur={this.validate} placeholder="Nome" />
                    </div>
                    <div className="field half">
                      <input type="text" id="email" required name="email" value={this.state.email} onChange={this.setEmail}  placeholder="Email" />
                    </div>
                    <div className="field">
                      <textarea name="message" id="message" required value={this.state.msg} onChange={this.setMsg} rows="4" placeholder="Informe o evento, a data e hora."></textarea>
                    </div>
                  </div>
                  <ul className="actions">
                    <li><input type="submit" value="Enviar" className="primary special" /></li>
                  </ul>
                </form>
              </section>
            </div>
          </div>
        );
    }
}