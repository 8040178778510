import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPage } from '../../store/page';

const Page = (props) => {

  const corpo = useSelector((state) => state.page);
  const dispatch = useDispatch();

   React.useEffect(() => {
    dispatch(loadPage(props.pagina));
  }, [dispatch]);

  React.useEffect(() => {
    setPage();
  }, [corpo]);

  function setPage() {
    console.log(corpo);
  }


  return (
    <div dangerouslySetInnerHTML={{ __html: corpo.dados.body }}></div>
    );
};

export default Page;
