import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import photo from './photo';
import photoPost from './photoPost';
import token from './token';
import user from './user';
import feed from './feed';
import ui from './ui';
import date from './date';
import inc2 from './incrementar';
import logger from './middleware/logger';
import localStorage from './middleware/localStorage';
import modal from './modal';
import login from './login';
import photos from './photos';
import categories from './categories';
import events from './events';
import banners from './banners';
import page from './page';
import pages from './pages';
import climatempo from './climatempo';

function inc(state = 0, action) {
  switch (action.type) {
    case 'INCREMENTAR':
      return state + 1;
    default:
      return state;
  }
}

const middleware = [...getDefaultMiddleware(), logger, localStorage];

const reducer = combineReducers({ photo, climatempo, photoPost, token, user, feed, ui, date, modal, inc, inc2, login, photos, categories, events, banners, page, pages });
const store = configureStore({ reducer, middleware });

export default store;
