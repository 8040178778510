import React from 'react';
import styles from './PhotosContent.module.css';
import { PHOTO_SET, PHOTO_DELETE } from '../Api';
import useFetch from '../Hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { abrir, fechar } from '../store/modal';

const PhotosContentItem = (props) => {

  const [title, setTitle] = React.useState(false);
  const [value, setValue] = React.useState('sem valor');
  const [titlebtn, setTitleBtn] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const { request, error } = useFetch();
  //const { modal } = useSelector((state) => state);
  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();
    const { url, options } = PHOTO_SET(props.id, { 'description' : value });
    const { response, json } = await request(url, options);
    if (response.ok) {
      console.log('deu certo');
    }
  }

  async function handleInfo(event) {
    event.preventDefault();
    //dispatch(abrir());
    setModal(!modal);
  }

  async function handleDelete(event) {
    event.preventDefault();
    var target = event.target;

    swal({
      title: "Deseja realmente apagar o registro ?",
      text: "Depois de deletado, você não poderá recuperar esse registro novamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      })
      .then((willDelete) => {
      if (willDelete) {

        async function Deletar() {
          const { url, options } = PHOTO_DELETE(props.id);
          const { response, json } = await request(url, options);
          if (response.ok) {
            target.innerHTML = json;
            target.disabled = true;
            target.parentNode.childNodes[2].disabled = true;
            setTitle(false);
            setTitleBtn(false);   
          }
        }
        Deletar();
        //return true;
      } else {
          //swal("Your imaginary file is safe!");
      }
      });

  }

  React.useEffect(() => {
    setValue(props.title);
  }, [props.title]);

  function handleItem(event) {
    event.preventDefault();
    setTitle(!title);
    setTitleBtn(!titlebtn);
    if (titlebtn) handleSubmit(event);

  }

  function changeItem(valor) {
    setValue(valor);
  }

  return (
    <>
          {title ? (
            <input className={styles.title} onChange={({ target }) => changeItem(target.value)} value={value}/>
          ) : (
            <h2 className={styles.title}>{value}</h2>
          )}

          {titlebtn ? (
            <button onClick={(event) => handleItem(event)}>Salvar</button>
          ) : (
            <button onClick={(event) => handleItem(event)}>Editar</button>
          )}

          <button onClick={(event) => handleDelete(event)}>Deletar</button>
          <div style={{ width : '24px', height : 'auto', cursor: 'pointer'}} onClick={(event) => handleInfo(event)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
          </svg>
          </div>

          {modal && <div className={styles.modalitem}>
            <div onClick={(event) => handleInfo(event)} className={styles.fechar}>X</div>
            <img src={props.src} alt={props.title} className={styles.img} />
            <div style={{ padding: '10px'}}>{props.title}</div>
          </div>}

    </>
  );
};

export default PhotosContentItem;
