import React from 'react';

const Relogio = () => {

let timer = 0;

React.useEffect(() => {
  timer = setInterval(relogioevent,1000);
},[])
  
function relogioevent(event) {
  let data = new Date();

  let hor=data.getHours();
  let min=data.getMinutes();
  let seg=data.getSeconds();
  
  if(hor < 10){
    hor="0"+hor;
  }
  if(min < 10){
    min="0"+min;
  }
  if(seg < 10){
    seg="0"+seg;
  }
  
  this.horas = hor + ":" + min;
  this.relogio.innerHTML = this.horas;
}

  return (
      <span id="relogio">00:00</span>
    );
};

export default Relogio;
