import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../store/login';
import styles from './Login.module.css';
import { UserContext } from '../UserContext';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const dispatch = useDispatch();

  const { userLogin, error, loading } = React.useContext(UserContext);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(login({ email, password }));
    //userLogin('', email, password);
  }

  return (
    <form className="anime" onSubmit={handleSubmit}>
      <label className={styles.label} htmlFor="email">
        Usuário
      </label>
      <input
        className={styles.input}
        id="email"
        type="text"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <label className={styles.label} htmlFor="password">
        Senha
      </label>
      <input
        className={styles.input}
        id="password"
        type="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />
      <button className={styles.button}>Enviar</button>
    </form>
  );
};

export default Login;
